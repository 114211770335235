import React from "react";
import BaseComponent from "./../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

//Components
import { Header } from "./Header.js";
import { About } from "./About.js";
import { ID } from "./ID.js";
import { Skills } from "./Skills.js";
import { BusinessAreas } from "./BusinessAreas.js";
import { Products } from "./Products.js";
import { Clients } from "./Clients.js";
import { Contacts } from "./Contacts/Contacts.js";

const StyledHome = styled(NanoFlex)`
  height: auto;
`;

export class Home extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  static displayName = Home.name;

  render() {
    return (
      <StyledHome flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        <Header />
        <About />
        <ID />
        <Skills />
        <BusinessAreas />
        <Products />
        <Clients />
        <Contacts />
      </StyledHome>
    );
  }
}
