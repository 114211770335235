import React from "react";
import BaseComponent from "./../../BaseComponent.js";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Components

//IMAGES
import { ReactComponent as AdlogLogo } from "../../Global/images/adlog-logo.svg";
import AdlogImage from "../../Global/images/adlogImage.png";
import { ReactComponent as MlogLogo } from "../../Global/images/mlog-logo.svg";
import MlogImage from "../../Global/images/mlogImage.png";

const StyledProducts = styled(NanoFlex)`
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  .background {
    clip-path: polygon(0 0, 48% 0, 100% 100%, 0% 100%);
    background-color: rgba(242, 242, 242, 1);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .productsWrapper {
    padding: 10vh 15vw;
    .titleSection {
      margin-bottom: 10vh;
      text-align: center;
      h3 {
        margin-bottom: 16px;
      }
    }
    .productsContainer {
      .productWrapper {
        margin-bottom: 5vh;
        .productCard {
          background-color: ${(props) => props.theme.color.main.white};
          box-shadow: ${(props) => props.theme.boxShadow};
          width: auto;

          .imageAside {
            max-width: 318px;
            background-repeat: no-repeat, repeat;
            background-size: cover;
            padding: 48px 32px;
            .logoWrapper {
              margin-bottom: 32px;
              svg {
                width: 160px;
                height: auto;
              }
            }
            .titleProduct {
              h5 {
                color: ${(props) => props.theme.color.main.white};
                text-transform: uppercase;
                margin-bottom: 16px;
              }
              p {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
          .infoAside {
            padding: 32px;
            position: relative;
            /* max-width: 600px; */
            max-width: 500px;
            min-width: 500px;
            height: auto;

            .infoProduct {
              h5 {
                text-transform: uppercase;
                margin-bottom: 8px;
              }
              p {
                line-height: 24px;
              }
            }
            .linkContainer {
              position: absolute;
              right: 16px;
              bottom: 16px;
              height: auto;
              width: auto;
              a {
                text-decoration: none;
                font-style: italic;
                h6 {
                  color: ${(props) => props.theme.color.main.blue};
                }
                &:hover {
                  text-decoration: underline;
                }
                &:visited {
                  color: ${(props) => props.theme.color.main.font};
                }
                &:hover {
                  color: ${(props) => props.theme.color.main.blue};
                }
                &:active {
                  color: ${(props) => props.theme.color.main.font};
                }
              }
            }
          }
        }
        .adlogCard {
          .imageAside {
            background-image: url(${AdlogImage});
            .titleProduct {
              text-align: right;
            }
          }
        }
        .mlogCard {
          .imageAside {
            background-image: url(${MlogImage});
          }
          .infoAside {
            .infoProduct {
              text-align: right;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  /* CAROUSEL */
  .carousel-root {
    width: 100%;
    height: 100%;
    .carousel-slider {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .control-arrow {
        display: none;
      }
      .slider-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        .slider {
          display: flex;
          width: 100%;
          .slide {
            width: 100%;
            min-width: 100%;
            background-color: unset;
          }
        }
      }
      .control-dots {
        position: absolute;
        bottom: 16px;
        right: 16px;
        display: flex;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 20px;
          margin-left: 6px;
          box-shadow: unset;
          cursor: pointer;
          background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
          &:hover {
            background-color: ${(props) => props.theme.color.colorsPalette.grey};
          }
        }
        .selected {
          pointer-events: none;
          background-color: ${(props) => props.theme.color.main.blue};
        }
      }
      .carousel-status {
        display: none;
      }
    }
    .carousel {
      .axis-vertical {
        display: none;
      }
    }
  }
  /* END CAROUSEL */

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1280px) {
    .productsWrapper {
      padding: 10vh 10vw;
    }
  }

  @media only screen and (max-width: 986px) {
    .productsWrapper {
      padding: 5vh 5vw;
      justify-content: center;
      .titleSection {
        margin-bottom: 5vh;
      }
    }
  }
  @media only screen and (max-width: 880px) {
    .productsWrapper {
      .productsContainer {
        .productWrapper {
          justify-content: center;
          .productCard {
            flex-direction: column;
            width: 70vw;
            .imageAside {
              width: 100%;
              max-width: unset;
            }
            .infoAside {
              min-height: 200px;
              min-width: 100%;
              padding: 16px 16px 32px 16px;
              .infoProduct {
                text-align: left;
              }
            }
          }
          .mlogCard {
            .imageAside {
              order: 0;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 650px) {
    .productsWrapper {
      .productsContainer {
        .productWrapper {
          .productCard {
            width: 90vw;
            .imageAside {
              padding: 32px 16px;
            }
          }
        }
      }
    }
  }
`;

export class Products extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledProducts id="products" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        <NanoFlex className="background" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000"></NanoFlex>
        <NanoFlex className="productsWrapper" flexDirection="column">
          <NanoFlex className="titleSection" flexDirection="column" data-aos="fade-up">
            <Type.h3>
              <b>{this.translation.products.title}</b>
            </Type.h3>
            <Type.p>{this.translation.products.subtitle}</Type.p>
          </NanoFlex>
          <NanoFlex className="productsContainer" flexDirection="column">
            {/* ADLOG  */}
            <NanoFlex className="productWrapper" justifyContent="flex-start" data-aos="fade-up-right">
              <NanoFlex className="productCard adlogCard" alignItems="stretch">
                <NanoFlex className="imageAside" flexDirection="column">
                  <NanoFlex className="logoWrapper" justifyContent="flex-end">
                    <AdlogLogo />
                  </NanoFlex>
                  <NanoFlex className="titleProduct" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                    <Type.h5>
                      <b>{this.translation.products.adlog.title}</b>
                    </Type.h5>
                    <Type.p>{this.translation.products.adlog.subtitle}</Type.p>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoAside">
                  <Carousel autoPlay infiniteLoop emulateTouch useKeyboardArrows stopOnHover>
                    {/* SLIDE 01 */}
                    <NanoFlex className="carouselItem">
                      <NanoFlex className="infoProduct" flexDirection="column" alignItems="flex-start">
                        <Type.h5>
                          <b>{this.translation.products.adlog.slides.slide01.title}</b>
                        </Type.h5>
                        <Type.p>{this.translation.products.adlog.slides.slide01.info}</Type.p>
                      </NanoFlex>
                    </NanoFlex>
                    {/* SLIDE 02 */}
                    <NanoFlex className="carouselItem">
                      <NanoFlex className="infoProduct" flexDirection="column" alignItems="flex-start">
                        <Type.h5>
                          <b>{this.translation.products.adlog.slides.slide02.title}</b>
                        </Type.h5>
                        <Type.p>{this.translation.products.adlog.slides.slide02.info}</Type.p>
                      </NanoFlex>
                    </NanoFlex>

                    {/* SLIDE 03 */}
                    <NanoFlex className="carouselItem">
                      <NanoFlex className="infoProduct" flexDirection="column" alignItems="flex-start">
                        <Type.h5>
                          <b>{this.translation.products.adlog.slides.slide03.title}</b>
                        </Type.h5>
                        <Type.p>{this.translation.products.adlog.slides.slide03.info}</Type.p>
                      </NanoFlex>
                    </NanoFlex>

                    {/* SLIDE 04 */}
                    <NanoFlex className="carouselItem">
                      <NanoFlex className="infoProduct" flexDirection="column" alignItems="flex-start">
                        <Type.h5>
                          <b>{this.translation.products.adlog.slides.slide04.title}</b>
                        </Type.h5>
                        <Type.p>{this.translation.products.adlog.slides.slide04.info}</Type.p>
                      </NanoFlex>
                    </NanoFlex>

                    {/* SLIDE 05 */}
                    <NanoFlex className="carouselItem">
                      <NanoFlex className="infoProduct" flexDirection="column" alignItems="flex-start">
                        <Type.h5>
                          <b>{this.translation.products.adlog.slides.slide05.title}</b>
                        </Type.h5>
                        <Type.p>{this.translation.products.adlog.slides.slide05.info}</Type.p>
                      </NanoFlex>
                    </NanoFlex>

                    {/* SLIDE 06 */}
                    <NanoFlex className="carouselItem">
                      <NanoFlex className="infoProduct" flexDirection="column" alignItems="flex-start">
                        <Type.h5>
                          <b>{this.translation.products.adlog.slides.slide06.title}</b>
                        </Type.h5>
                        <Type.p>{this.translation.products.adlog.slides.slide06.info}</Type.p>
                      </NanoFlex>
                    </NanoFlex>

                    {/* SLIDE 06 */}
                    <NanoFlex className="carouselItem">
                      <NanoFlex className="infoProduct" flexDirection="column" alignItems="flex-start">
                        <Type.h5>
                          <b>{this.translation.products.adlog.slides.slide07.title}</b>
                        </Type.h5>
                        <Type.p>{this.translation.products.adlog.slides.slide07.info}</Type.p>
                      </NanoFlex>
                    </NanoFlex>
                  </Carousel>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>

            {/* MLOG  */}
            <NanoFlex className="productWrapper" justifyContent="flex-end" data-aos="fade-up-left">
              <NanoFlex className="productCard mlogCard" alignItems="stretch">
                <NanoFlex className="imageAside" order="1" flexDirection="column">
                  <NanoFlex className="logoWrapper" justifyContent="flex-start">
                    <MlogLogo />
                  </NanoFlex>
                  <NanoFlex className="titleProduct" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.h5>
                      <b>{this.translation.products.mlog.title}</b>
                    </Type.h5>
                    <Type.p>{this.translation.products.mlog.subtitle}</Type.p>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="infoAside" flexDirection="column">
                  <NanoFlex className="infoProduct">
                    <Type.p dangerouslySetInnerHTML={{ __html: this.translation.products.mlog.info }}></Type.p>
                  </NanoFlex>
                  <NanoFlex className="linkContainer" justifyContent="flex-end">
                    <a href="https://mlog.medialog.pt/" rel="noopener noreferrer" target="_blank">
                      <Type.h6>{this.translation.products.mlog.linkTitle}</Type.h6>
                    </a>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledProducts>
    );
  }
}
