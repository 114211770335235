export const elementMatches = (el, selector) => {
  return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
};

export const elementMatchesAny = (el, selectorArray) => {
  for (let idx = 0; idx < selectorArray.length; idx++) {
    let v = selectorArray[idx];
    if (elementMatches(el, v) || el.parentElement.closest(v)) {
      return true;
    }
  }
  return false;
};

export const validateEmailFormat = function (val) {
  var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(val.toLowerCase());
};
