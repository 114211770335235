import React from "react";
import BaseComponent from "./../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex.js";
import Type from "../../../Global/Typography.js";
import { validateEmailFormat } from "../../../Global/tools.js";
//Components
import CTAButton from "../../../Global/Elements/Buttons/CTAbutton.js";
import Input from "../../../Global/Elements/Forms/Input.js";
import Textarea from "../../../Global/Elements/Forms/Textarea.js";
import Checkbox from "../../../Global/Elements/Forms/Checkbox.js";
import Loading from "../../../Animations/Loading.js";

//Services
import homeService from "./../../../../services/HomeService";

const StyledContactForm = styled(NanoFlex)`
  height: auto;
  padding: 15vh 48px;
  background-color: ${(props) => props.theme.color.main.darkBlue};
  .formContainer {
    width: 60%;
    .headerForm {
      .headerInfo {
        margin-bottom: 16px;
        h5 {
          color: ${(props) => props.theme.color.main.white};
        }
        h3 {
          color: ${(props) => props.theme.color.main.white};
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .formWrapper {
      margin-top: 32px;
      .formRow {
        margin-bottom: 16px;
        .inputWrapper {
          margin-right: 16px;
          .title {
            color: ${(props) => props.theme.color.main.white};
          }
          &:last-child {
            margin-right: 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        h6 {
          /* font-size: 10px; */
          opacity: 0.7;
          color: ${(props) => props.theme.color.main.white};
        }
      }
      .submitFormRow {
        .termsForm {
          width: auto;
          margin-right: 16px;
          h6 {
            margin-left: 8px;
            color: ${(props) => props.theme.color.main.white};
            a {
              text-decoration: none;
              color: ${(props) => props.theme.color.main.yellow};
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .submitBtn {
          width: auto;
        }
        .submitLoading {
          width: 200px;
          height: 39.2px;
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 940px) {
    .formContainer {
      width: 70%;
    }
  }
  @media only screen and (max-width: 820px) {
    .formContainer {
      width: 80%;
    }
  }
  @media only screen and (max-width: 720px) {
    .formContainer {
      width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 48px;
    .formContainer {
      .formWrapper {
        .formRow {
          flex-direction: column;
          .inputWrapper {
            margin-right: 0;
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .submitFormRow {
          flex-direction: column;
          .termsForm {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 440px) {
    padding: 48px 32px;
    .formContainer {
      .formWrapper {
        .formRow {
          flex-direction: column;
          .inputWrapper {
            margin-right: 0;
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .submitFormRow {
          flex-direction: column;
          .termsForm {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`;

export class ContactForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      name: "",
      company: "",
      email: "",
      phone: "",
      message: "",
      loading: false,
      acceptedTerms: false,
    };
  };
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  isSendDisabled = () => {
    return this.state.name?.trim() === "" || this.state.email?.trim() === "" || validateEmailFormat(this.state.email?.trim()) === false || (this.state.message?.trim() === "") | (this.state.acceptedTerms === false);
  };

  sendContactUsEmail = () => {
    this.setState({ loading: true }, () => {
      homeService
        .sendContact({
          name: this.state.name,
          company: this.state.company,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
          }
        })
        .then((data) => {
          if (data === "OK") {
            this.setState(this.getDefaultState());
          } else {
            //ERROR
            this.setState({ loading: false });
          }
        });
    });
  };

  render() {
    return (
      <StyledContactForm data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
        <NanoFlex className="formContainer" flexDirection="column" data-aos="fade-up" data-aos-duration="1500">
          <NanoFlex className="headerForm" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="headerInfo" justifyContent="flex-start">
              <Type.h5>{this.translation.contacts.form.topSubtitle}</Type.h5>
            </NanoFlex>
            <NanoFlex className="headerInfo" justifyContent="flex-start">
              <Type.h3>
                <b>{this.translation.contacts.form.title}</b>
              </Type.h3>
            </NanoFlex>
            <NanoFlex className="headerInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <Type.h5>{this.translation.contacts.form.subtitle}</Type.h5>
              <Type.h5>{this.translation.contacts.form.subtitle2}</Type.h5>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="formWrapper" flexDirection="column">
            <NanoFlex className="formRow">
              <NanoFlex className="inputWrapper">
                <Input
                  title={`${this.translation.contacts.form.name} *`}
                  placeholder={this.translation.contacts.form.placeholderName}
                  type="text"
                  defaultValue={this.state.name}
                  onChangeAction={(v) => {
                    this.setState({ name: v });
                  }}
                />
              </NanoFlex>
              <NanoFlex className="inputWrapper">
                <Input
                  title={this.translation.contacts.form.company}
                  placeholder={this.translation.contacts.form.placeholderCompany}
                  type="text"
                  defaultValue={this.state.company}
                  onChangeAction={(v) => {
                    this.setState({ company: v });
                  }}
                />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="formRow">
              <NanoFlex className="inputWrapper">
                <Input
                  title={`${this.translation.contacts.form.email} *`}
                  placeholder={this.translation.contacts.form.placeholderEmail}
                  type="text"
                  defaultValue={this.state.email}
                  onChangeAction={(v) => {
                    this.setState({ email: v });
                  }}
                />
              </NanoFlex>
              <NanoFlex className="inputWrapper">
                <Input
                  title={this.translation.contacts.form.phone}
                  placeholder={this.translation.contacts.form.placeholderPhone}
                  numeric
                  maxLength={9}
                  type="text"
                  defaultValue={this.state.phone}
                  onChangeAction={(v) => {
                    this.setState({ phone: v });
                  }}
                />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="formRow">
              <NanoFlex className="inputWrapper">
                <Textarea
                  title={`${this.translation.contacts.form.yourIdea} *`}
                  placeholder={this.translation.contacts.form.placeholderYourIdea}
                  defaultValue={this.state.message}
                  onChangeAction={(v) => {
                    this.setState({ message: v });
                  }}
                />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="formRow" justifyContent="flex-start">
              <Type.h6>{this.translation.contacts.form.mandatoryFieldsMessage}</Type.h6>
            </NanoFlex>
            <NanoFlex className="submitFormRow" justifyContent="space-between">
              <NanoFlex className="termsForm">
                <Checkbox
                  checked={this.state.acceptedTerms}
                  onCheckedChange={(v) => {
                    this.setState({ acceptedTerms: v });
                  }}
                />
                <Type.h6 dangerouslySetInnerHTML={{ __html: this.translation.contacts.form.privacyTerms }}></Type.h6>
              </NanoFlex>
              {!this.state.loading && (
                <NanoFlex className="submitBtn">
                  <CTAButton label={this.translation.global.send} disabled={this.isSendDisabled()} clickAction={this.sendContactUsEmail} />
                </NanoFlex>
              )}
              {this.state.loading && (
                <NanoFlex className="submitBtn submitLoading">
                  <Loading />
                </NanoFlex>
              )}
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledContactForm>
    );
  }
}
