import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import AOS from "aos";

import "aos/dist/aos.css";

//Components
import { Navbar } from "./Navbar.js";
import MenuMobile from "./MenuMobile.js";
import { Footer } from "./Footer.js";


const StyledLayout = styled(NanoFlex)`
  /* overflow: ${(props) => (!props.scrollable ? "hidden" : "auto")}; */
  height: ${(props) => (!props.scrollable ? "100%" : "auto")};
  width: 100vw;
  overflow: hidden;
`;

export class Layout extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMenuBurgerOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.render !== this.props.render) {
      this.scrollInto();
      this.onMenuBurgerOpenChange(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }

  handleLoad = () => {
    this.scrollInto();
  };

  scrollInto = () => {
    setTimeout(() => {
      let element = this.props.render.location.hash;
      if (element && element.trim() !== "" && element.trim() !== "#") {
        let el = document.getElementById(element.substring(1));
        let yOffset = document.getElementsByClassName("nav")[0].clientHeight * -1;
        let y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
      }
    }, 250);
  };

  onMenuBurgerOpenChange = (val) => {
    this.setState({ isMenuBurgerOpen: val });
  };

  render() {
    AOS.init({
      duration: 1200,
    })

    return (
      <StyledLayout flexDirection="column" justifyContent="flex-start" scrollable={!this.state.isMenuBurgerOpen}>
          <Navbar menuBurgerOpen={this.state.isMenuBurgerOpen} onMenuBurgerOpenChange={this.onMenuBurgerOpenChange} />
          <MenuMobile menuBurgerOpen={this.state.isMenuBurgerOpen} onMenuBurgerOpenChange={this.onMenuBurgerOpenChange} />

          {React.cloneElement(this.props.children, { render: this.props.render })}
          <Footer />
      </StyledLayout>
    );
  }
}
