import React from "react";
import BaseComponent from "./../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex.js";
import Type from "../../../Global/Typography.js";

// IMAGES
import MedialogLocaltion from "../../../Global/images/medialog_location.png";
import { ReactComponent as HeadPhones } from "../../../Global/icons/headphones.svg";
import { ReactComponent as PTflag } from "../../../Global/icons/PTflag.svg";
import { ReactComponent as ESflag } from "../../../Global/icons/ESflag.svg";

const StyledContactInfo = styled(NanoFlex)`
  height: auto;
  background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
  .addressAside {
    padding: 48px;
    .mapContainer {
      width: 300px;
      height: 240px;
      background-color: ${(props) => props.theme.color.main.white};
      overflow: hidden;
      padding: 8px;
      margin-right: 32px;
      a {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: ${(props) => props.theme.transition};
        }
        &:hover {
          img {
            transform: scale(1.3);
            transition: ${(props) => props.theme.transition};
          }
        }
      }
    }
    .addressWrapper {
      width: auto;
      .addressInfo {
        height: auto;
        margin-bottom: 16px;
        h3 {
          text-transform: uppercase;
        }
        p {
          text-transform: uppercase;
        }
        a {
          text-decoration: none;
          h6 {
            transition: ${(props) => props.theme.transition};
          }
          &:hover {
            h6 {
              transition: ${(props) => props.theme.transition};
              color: ${(props) => props.theme.color.main.blue};
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .supportAside {
    width: auto;
    .labelContainer {
      height: auto;
      width: auto;
      min-width: 330px;
      background-color: ${(props) => props.theme.color.main.darkBlue};
      padding: 32px 64px 32px 32px;
      margin-bottom: 32px;
      .iconWrapper {
        margin-right: 32px;
        width: auto;
        svg {
          height: 62px;
          width: auto;
          path {
            fill: ${(props) => props.theme.color.main.white};
          }
        }
      }
      .linksWrapper {
        .title {
          margin-bottom: 8px;
          p {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.white};
          }
        }
        .itemLink {
          margin-bottom: 8px;
          a {
            text-decoration: none;
            display: flex;
            align-items: center;

            .icon {
              margin-right: 8px;
              svg {
                height: 14px;
                width: auto;
              }
            }
            .country {
              h6 {
                color: ${(props) => props.theme.color.main.white};
                text-transform: uppercase;
                transition: ${(props) => props.theme.transition};
              }
            }
            &:hover {
              .country {
                h6 {
                  font-weight: bold;
                  transition: ${(props) => props.theme.transition};
                }
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1020px) {
    flex-direction: column;
    border-bottom: 1px solid ${(props) => props.theme.color.main.white};
    .addressAside {
      justify-content: center;
    }
    .supportAside {
      width: 100%;
      .labelContainer {
        width: 100%;
        margin-bottom: 0px;
        padding: 16px 32px 16px 32px;
        .iconWrapper {
          margin-right: 16px;
          svg {
            height: 26px;
          }
        }
        .linksWrapper {
          flex-direction: row;
          width: auto;
          .title {
            margin-bottom: 0;
            width: auto;
          }
          .itemLink {
            width: auto;
            margin-left: 16px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 690px) {
    .addressAside {
      padding: 32px;
      flex-direction: column;
      .mapContainer {
        width: 100%;
        height: 280px;
        min-height: 280px;
        order: 1;
        margin-right: 0;
        margin-top: 32px;
      }
      .addressWrapper {
        align-items: flex-start;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 440px) {
    .supportAside {
      .labelContainer {
        .iconWrapper {
          display: none;
        }
        .linksWrapper {
          flex-direction: column;
          .title {
            margin-bottom: 8px;
          }
          .itemLink {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
`;

export class ContactInfo extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledContactInfo>
        <NanoFlex className="addressAside" justifyContent="flex-start" data-aos="fade-right">
          <NanoFlex className="mapContainer">
            <a href="https://www.google.com/maps/place/R.+Padre+Lu%C3%ADs+Apar%C3%ADcio+10,+1150-171+Lisboa/@38.7244577,-9.144611,17.75z/data=!4m5!3m4!1s0xd19339d1f98c05b:0x11764c45e60f00c2!8m2!3d38.724828!4d-9.1429944?shorturl=1" rel="noopener noreferrer" target="_blank">
              <img src={MedialogLocaltion} alt="Medialog Location" />
            </a>
          </NanoFlex>
          <NanoFlex className="addressWrapper" flexDirection="column" alignItems="flex-start" >
            <NanoFlex className="addressInfo" justifyContent="flex-start">
              <Type.h3>
                <b>{this.translation.contacts.info.title}</b>
              </Type.h3>
            </NanoFlex>
            <NanoFlex className="addressInfo " flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <Type.p>{this.translation.contacts.info.address}</Type.p>
              <Type.p>{this.translation.contacts.info.postalCode}</Type.p>
            </NanoFlex>
            <NanoFlex className="addressInfo" justifyContent="flex-start">
              <Type.h6>{this.translation.contacts.info.phone}</Type.h6>
            </NanoFlex>
            <NanoFlex className="addressInfo" justifyContent="flex-start">
              <a href={`mailto:${this.translation.contacts.info.email}`} rel="noopener noreferrer" target="_blank">
                <Type.h6>
                  <b>{this.translation.contacts.info.email}</b>
                </Type.h6>
              </a>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="supportAside" justifyContent="flex-end" alignItems="flex-end">
          <NanoFlex className="labelContainer">
            <NanoFlex className="iconWrapper">
              <HeadPhones />
            </NanoFlex>
            <NanoFlex className="linksWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="title">
                <Type.p>
                  <b>{this.translation.contacts.info.supportTitle}</b>
                </Type.p>
              </NanoFlex>
              <NanoFlex className="itemLink" justifyContent="flex-start">
                <a href="http://898.tv/medialogpt" rel="noopener noreferrer" target="_blank">
                  <NanoFlex className="icon">
                    <PTflag />
                  </NanoFlex>
                  <NanoFlex className="country">
                    <Type.h6>{this.translation.contacts.info.portugal}</Type.h6>
                  </NanoFlex>
                </a>
              </NanoFlex>
              <NanoFlex className="itemLink" justifyContent="flex-start">
                <a href="http://898.tv/v14/adlog" rel="noopener noreferrer" target="_blank">
                  <NanoFlex className="icon">
                    <ESflag />
                  </NanoFlex>
                  <NanoFlex className="country">
                    <Type.h6>{this.translation.contacts.info.spain}</Type.h6>
                  </NanoFlex>
                </a>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledContactInfo>
    );
  }
}
