import React from "react";
import BaseComponent from "./../../BaseComponent.js";
import styled from "styled-components";
import ScrollspyNav from "react-scrollspy-nav";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import CTAButton from "../../Global/Elements/Buttons/CTAbutton.js";

//IMAGES
import { ReactComponent as MegaphoneIcon } from "../../Global/icons/megaphone.svg";
import { ReactComponent as ClapperboardIcon } from "../../Global/icons/clapperboard.svg";
import { ReactComponent as HumanResourcesIcon } from "../../Global/icons/humanResources.svg";
import { ReactComponent as EnergyIcon } from "../../Global/icons/energy.svg";

const StyledBusinessAreas = styled(NanoFlex)`
  height: auto;
  .areasWrapper {
    height: auto;
    /* padding: 15vh 48px; */
    padding: 10vh 48px;
    background-color: ${(props) => props.theme.color.main.darkBlue};
    .sectionTitle {
      height: auto;
      width: 576px;
      margin-bottom: 5vh;
      .title {
        h2 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
    .areasContainer {
      width: auto;
      .areasColumn {
        width: auto;
        margin-right: 32px;
        .areaItem {
          margin-bottom: 5vh;
          .areaIcon {
            width: 88px;
            margin-right: 16px;
            svg {
              height: 62px;
              width: auto;
              path {
                fill: ${(props) => props.theme.color.main.white};
              }
            }
          }
          .areaName {
            max-width: 218px;
            h4 {
              color: ${(props) => props.theme.color.main.white};
              text-transform: uppercase;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .contactWrapper {
    height: auto;
    width: 550px;
    /* padding: 15vh 48px; */
    padding: 10vh 48px;
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    .infoContainer {
      .titleWrapper {
        height: auto;
        margin-bottom: 48px;
        h2 {
          text-transform: uppercase;
          text-align: center;
        }
      }
      .infoWrapper {
        height: auto;
        margin-bottom: 48px;
        text-align: center;
        h4 {
          margin-bottom: 16px;
        }
      }
      .ctaWrapper {
        height: auto;
        a {
          text-decoration: none;
        }
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 1060px) {
    .areasWrapper {
      padding: 10vh 32px;
      .sectionTitle {
        margin-bottom: 5vh;
      }
      .areasContainer {
        .areasColumn {
          .areaItem {
            margin-bottom: 5vh;
          }
        }
      }
    }
    .contactWrapper {
      padding: 10vh 32px;
    }
  }

  @media only screen and (max-width: 940px) {
    flex-direction: column;
    .areasWrapper {
      padding: 5vh 32px;
      .sectionTitle {
        margin-bottom: 5vh;
      }
    }
    .contactWrapper {
      width: 100%;
      padding: 5vh 32px;
      background-color: ${(props) => props.theme.color.main.white};
      .infoContainer {
        .titleWrapper {
          margin-bottom: 16px;
          h2 {
            font-size: 32px;
          }
        }
        .infoWrapper {
          margin-bottom: 16px;
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    .areasWrapper {
      .sectionTitle {
        width: 100%;
        .title {
          justify-content: center;
          text-align: center;
        }
      }
      .areasContainer {
        flex-direction: column;
        .areasColumn {
          margin-right: 0px;
          margin-bottom: 32px;
          .areaItem {
            margin-bottom: 32px;
            .areaIcon {
              max-height: 52px;
              max-width: 52px;
              min-width: 52px;
              svg {
                height: 48px;
              }
            }
            .areaName {
              max-width: unset;
              h4 {
                font-size: 20px;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 460px) {
    flex-direction: column;
    .areasWrapper {
      padding: 5vh 16px;
      .sectionTitle {
        .title {
          h2 {
            font-size: 30px;
          }
        }
      }
      .areasContainer {
        .areasColumn {
          .areaItem {
            .areaName {
              h4 {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .contactWrapper {
      padding: 5vh 16px;
      .infoContainer {
        .titleWrapper {
          h2 {
            font-size: 30px;
          }
        }
      }
    }
  }
`;

export class BusinessAreas extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledBusinessAreas id="businessArea" alignItems="stretch" alignContent="stretch">
        <NanoFlex className="areasWrapper" flexDirection="column" data-aos="fade-right" data-aos-easing="ease-out-cubic">
          <NanoFlex className="sectionTitle">
            <NanoFlex className="title" justifyContent="flex-start">
              <Type.h2>{this.translation.businessAreas.title}</Type.h2>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="areasContainer" alignItems="flex-start">
            <NanoFlex className="areasColumn" flexDirection="column">
              {/* ITEM advertisingAndMediaAgencies*/}
              <NanoFlex className="areaItem" data-aos="fade-up" data-aos-duration="2200">
                <NanoFlex className="areaIcon" data-aos="flip-left" data-aos-duration="2300">
                  <MegaphoneIcon />
                </NanoFlex>
                <NanoFlex className="areaName" justifyContent="flex-start">
                  <Type.h4>
                    <b>{this.translation.businessAreas.advertisingAndMediaAgencies}</b>
                  </Type.h4>
                </NanoFlex>
              </NanoFlex>
              {/* ITEM humanResources*/}
              <NanoFlex className="areaItem" data-aos="fade-up" data-aos-duration="2200">
                <NanoFlex className="areaIcon" data-aos="flip-left" data-aos-duration="2300">
                  <HumanResourcesIcon />
                </NanoFlex>
                <NanoFlex className="areaName" justifyContent="flex-start">
                  <Type.h4>
                    <b>{this.translation.businessAreas.humanResources}</b>
                  </Type.h4>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="areasColumn" flexDirection="column">
              {/* ITEM audiences*/}
              <NanoFlex className="areaItem"  data-aos="fade-up" data-aos-duration="2200">
                <NanoFlex className="areaIcon" data-aos="flip-left" data-aos-duration="2300">
                  <ClapperboardIcon />
                </NanoFlex>
                <NanoFlex className="areaName" justifyContent="flex-start">
                  <Type.h4>
                    <b>{this.translation.businessAreas.audiences}</b>
                  </Type.h4>
                </NanoFlex>
              </NanoFlex>

              {/* ITEM energy*/}
              <NanoFlex className="areaItem" data-aos="fade-up" data-aos-duration="2200">
                <NanoFlex className="areaIcon" data-aos="flip-left" data-aos-duration="2300">
                  <EnergyIcon />
                </NanoFlex>
                <NanoFlex className="areaName" justifyContent="flex-start">
                  <Type.h4>
                    <b>{this.translation.businessAreas.energy}</b>
                  </Type.h4>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="contactWrapper" data-aos="fade-left" data-aos-easing="ease-out-cubic" data-aos-duration="2100">
          <NanoFlex className="infoContainer" flexDirection="column">
            <NanoFlex className="titleWrapper" data-aos="zoom-out" data-aos-duration="2500">
              <Type.h2>
                <b>{this.translation.global.successLink}</b>
              </Type.h2>
            </NanoFlex>
            <NanoFlex className="infoWrapper" flexDirection="column">
              <Type.h4>
                <b>{this.translation.businessAreas.startToday}</b>
              </Type.h4>
              <Type.p>{this.translation.businessAreas.allSoftwareType}</Type.p>
            </NanoFlex>
            <NanoFlex className="ctaWrapper">
              <ScrollspyNav scrollTargetIds={["contacts"]} scrollDuration="500" headerBackground="true">
                <a href="#contacts">
                  <CTAButton label={this.translation.global.cta} />
                </a>
              </ScrollspyNav>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledBusinessAreas>
    );
  }
}
