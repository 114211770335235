import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//IMAGES
import { ReactComponent as Facebook } from "./../../Global/icons/facebook.svg";
import { ReactComponent as Instagram } from "./../../Global/icons/instagram.svg";
import { ReactComponent as Linkedin } from "./../../Global/icons/linkedin.svg";

const StyledFooter = styled(NanoFlex)`
  height: auto;
  background-color: ${(props) => props.theme.color.main.darkBlue};
  padding: 16px 32px;
  user-select: none;
  .footerWrapper {
    .footerBlock {
      width: auto;
      h6 {
        font-size: 10px;
        color: ${(props) => props.theme.color.main.white};
      }
      .socialIcon {
        margin-left: 16px;
        transition: ${(props) => props.theme.transition};
        a {
          text-decoration: none;
          svg {
            height: 18px;
            width: auto;
            path {
              fill: ${(props) => props.theme.color.main.white};
            }
          }
        }
        &:hover {
          opacity: 0.7;
          transition: ${(props) => props.theme.transition};
        }
      }
    }
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 480px) {
    padding: 16px;
    .footerWrapper {
      flex-direction: column;
      .leftAside {
        order: 1;
        text-align: center;
      }
      .socialWrapper {
        order: 0;
        margin-bottom: 8px;
      }
    }
  }
`;

export class Footer extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledFooter>
        <NanoFlex className="footerWrapper" justifyContent="space-between">
          <NanoFlex className="footerBlock leftAside">
            <Type.h6>{this.translation.footer.footerInfo}</Type.h6>
          </NanoFlex>

          <NanoFlex className="footerBlock socialWrapper">
            <NanoFlex className="socialIcon">
              <a href="https://www.facebook.com/Medialogeuropa" rel="noopener noreferrer" target="_blank">
                <Facebook />
              </a>
            </NanoFlex>
            <NanoFlex className="socialIcon">
              <a href="https://www.instagram.com/medialogeuropa/" rel="noopener noreferrer" target="_blank">
                <Instagram />
              </a>
            </NanoFlex>
            <NanoFlex className="socialIcon">
              <a href="https://www.linkedin.com/company/medialog-europa/" rel="noopener noreferrer" target="_blank">
                <Linkedin />
              </a>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledFooter>
    );
  }
}
