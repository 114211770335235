import React, { Component } from 'react';
// import { Route } from 'react-router';
import { BrowserRouter, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Layout } from './components/views/Shared/Layout.js';
import { Home } from './components/views/Homepage/Home';
import RenderRoute from "./components/Global/RenderRoute";



// Themes 
import defaultTheme from "./themes/default.json";

//Guidelines
import { Guidelines } from "./components/views/Guidelines/Guidelines.js";

//STYLES
import "./styles/reset.scss";
import "./styles/config.scss";
import "aos/dist/aos.css";

export default class App extends Component {
  static displayName = App.name;


  render () {

    return (
      
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Switch>
             {/* Home */}
             <RenderRoute exact path="/">
              <Layout>
                <Home />
              </Layout>
            </RenderRoute>
          
            {/* GUIDELINES */}
            {window.location.hostname === "localhost" && (
              <RenderRoute exact path="/guidelines">
                <Guidelines />
              </RenderRoute>
            )}

          </Switch>
        </BrowserRouter>
      </ThemeProvider>
      
    );
  }
}
