import React from "react";
import BaseComponent from "./../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
// import AOS from "aos";

// import "aos/dist/aos.css";

//IMAGES
import { ReactComponent as IDIcon } from "../../Global/icons/id.svg";
import { ReactComponent as MedialogLogo } from "../../Global/images/MEDIALOG-logo2.svg";
import { ReactComponent as CalendarIcon } from "../../Global/icons/calendar.svg";
import { ReactComponent as PinIcon } from "../../Global/icons/pin.svg";
import { ReactComponent as SoftwarehouseIcon } from "../../Global/icons/softwarehouse.svg";
import { ReactComponent as BrainIcon } from "../../Global/icons/brain.svg";
import { ReactComponent as BoxIcon } from "../../Global/icons/box.svg";
import { ReactComponent as HandsIcon } from "../../Global/icons/hands.svg";

const StyledID = styled(NanoFlex)`
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  .background {
    clip-path: polygon(48% 0, 100% 0, 100% 100%, 0% 100%);
    background-color: rgba(242, 242, 242, 1);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .idWrapper {
    padding: 15vh 10vw;
    .idCardContainer {
      width: auto;
      .idCardWrapper {
        background-color: ${(props) => props.theme.color.main.white};
        border-radius: 20px;
        padding: 16px;
        box-shadow: ${(props) => props.theme.boxShadow};
        border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
        margin-right: 5vw;
        .logoWrapper {
          background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
          border-radius: 20px 0 0 20px;
          width: auto;
          padding: 32px;
          height: auto;
          svg {
            height: 96px;
            width: auto;
          }
        }
        .middleInfo {
          padding: 32px;
          height: auto;
          .itemInfo {
            margin-bottom: 16px;
            .iconWrapper {
              width: auto;
              min-width: 42px;
              margin-right: 16px;
              svg {
                height: 32px;
                width: auto;
                path {
                  fill: ${(props) => props.theme.color.main.font};
                }
              }
            }
            .detailsInfo {
              width: auto;
              height: auto;
              p {
                text-transform: uppercase;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .rightInfo {
          height: auto;
          .itemInfo {
            margin-bottom: 16px;
            padding: 16px 32px;
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
            .iconWrapper {
              width: auto;
              min-width: 42px;
              margin-right: 16px;
              svg {
                height: 32px;
                width: auto;
                path {
                  fill: ${(props) => props.theme.color.main.font};
                }
              }
            }
            .detailsInfo {
              width: auto;
              p {
                text-transform: uppercase;
              }
            }
            &:first-child {
              border-radius: 0 20px 0 0;
            }
            &:last-child {
              margin-bottom: 0;
              border-radius: 0 0 20px 0;
            }
          }
        }
      }
    }
    .idInfoWrapper {
      width: 30vw;
      .idTitle {
        border-bottom: 1px dashed ${(props) => props.theme.color.main.font};
        padding-bottom: 24px;
        .iconWrapper {
          width: auto;
          height: auto;
          margin-right: 16px;
          svg {
            height: 120px;
            width: auto;
            path {
              fill: ${(props) => props.theme.color.main.font};
            }
          }
        }

        h1 {
          font-size: 120px;
        }
      }
      .idInfo {
        margin-top: 24px;
        p {
          a {
            &:visited {
              color: ${(props) => props.theme.color.main.font};
            }
            &:hover {
              color: ${(props) => props.theme.color.main.blue};
            }
            &:active {
              color: ${(props) => props.theme.color.main.font};
            }
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1260px) {
    .idWrapper {
      padding: 10vh 5vw;
    }
  }
  @media only screen and (max-width: 1090px) {
    .idWrapper {
      flex-direction: column;
      .idCardContainer {
        order: 1;
        margin-top: 5vh;
        .idCardWrapper {
          margin-right: 0;
        }
      }
      .idInfoWrapper {
        width: 60vw;
      }
    }
  }

  @media only screen and (max-width: 690px) {
    .idWrapper {
      padding: 5vh 16px;
      .idCardContainer {
        width: 100%;
        .idCardWrapper {
          .logoWrapper {
            display: none;
          }
          .middleInfo {
            padding: 16px;
          }
          .rightInfo {
            .itemInfo {
              padding: 16px;
              margin-bottom: 8px;
            }
          }
        }
      }
      .idInfoWrapper {
        .idTitle {
          .iconWrapper {
            svg {
              height: 80px;
            }
          }
          h1 {
            font-size: 80px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .idWrapper {
      .idCardContainer {
        .idCardWrapper {
          flex-direction: column;
          .logoWrapper {
          }
          .middleInfo {
            .itemInfo {
              .detailsInfo {
                flex-direction: row;
                flex-wrap: wrap;
                p {
                  margin-right: 8px;
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }
          .rightInfo {
            .itemInfo {
              .detailsInfo {
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                h4 {
                  margin-right: 8px;
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
            &:first-child {
              border-radius: 0;
            }
            &:last-child {
              border-radius: 0 0 20px 20px;
            }
          }
        }
      }
    }
  }
`;

export class ID extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledID id="id">
        <NanoFlex className="background" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000"></NanoFlex>
        <NanoFlex className="idWrapper" data-aos="fade-up">
          <NanoFlex className="idCardContainer"  data-aos="flip-down">
            <NanoFlex className="idCardWrapper" alignItems="stretch" alignContent="stretch">
              <NanoFlex className="logoWrapper">
                <MedialogLogo />
              </NanoFlex>
              <NanoFlex className="middleInfo" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="itemInfo" justifyContent="flex-start">
                  <NanoFlex className="iconWrapper">
                    <CalendarIcon />
                  </NanoFlex>
                  <NanoFlex className="detailsInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>{this.translation.id.cardInfo.bornDate}</Type.p>
                    <Type.p>
                      <b>{this.translation.id.cardInfo.date}</b>
                    </Type.p>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="itemInfo" justifyContent="flex-start">
                  <NanoFlex className="iconWrapper">
                    <PinIcon />
                  </NanoFlex>
                  <NanoFlex className="detailsInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>{this.translation.id.cardInfo.location}</Type.p>
                    <Type.p>
                      <b>{this.translation.id.cardInfo.lisbonAndMadrid}</b>
                    </Type.p>
                  </NanoFlex>
                </NanoFlex>
                <NanoFlex className="itemInfo" justifyContent="flex-start">
                  <NanoFlex className="iconWrapper">
                    <SoftwarehouseIcon />
                  </NanoFlex>
                  <NanoFlex className="detailsInfo" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <Type.p>{this.translation.id.cardInfo.coreBussiness}</Type.p>
                    <Type.p>
                      <b>{this.translation.id.cardInfo.softwarehouse}</b>
                    </Type.p>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="rightInfo" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="itemInfo" justifyContent="flex-start">
                  <NanoFlex className="iconWrapper">
                    <BrainIcon />
                  </NanoFlex>
                  <NanoFlex className="detailsInfo" flexDirection="column" alignItems="flex-start">
                    <Type.h4>
                      <b>{this.translation.id.cardInfo.teamMembers}</b>
                    </Type.h4>
                    <Type.p>{this.translation.id.cardInfo.team}</Type.p>
                  </NanoFlex>
                </NanoFlex>

                <NanoFlex className="itemInfo" justifyContent="flex-start">
                  <NanoFlex className="iconWrapper">
                    <BoxIcon />
                  </NanoFlex>
                  <NanoFlex className="detailsInfo" flexDirection="column" alignItems="flex-start">
                    <Type.h4>
                      <b>{this.translation.id.cardInfo.productsNumber}</b>
                    </Type.h4>
                    <Type.p>{this.translation.id.cardInfo.products}</Type.p>
                  </NanoFlex>
                </NanoFlex>

                <NanoFlex className="itemInfo" justifyContent="flex-start">
                  <NanoFlex className="iconWrapper">
                    <HandsIcon />
                  </NanoFlex>
                  <NanoFlex className="detailsInfo" flexDirection="column" alignItems="flex-start">
                    <Type.h4>
                      <b>{this.translation.id.cardInfo.clientsNumber}</b>
                    </Type.h4>
                    <Type.p>{this.translation.id.cardInfo.clients}</Type.p>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="idInfoWrapper" flexDirection="column" alignItems="flex-start" data-aos="fade-left">
            <NanoFlex className="idTitle" justifyContent="flex-start">
              <NanoFlex className="iconWrapper">
                <IDIcon />
              </NanoFlex>
              <Type.h1>
                <b>{this.translation.navbar.id}</b>
              </Type.h1>
            </NanoFlex>
            <NanoFlex className="idInfo">
              <Type.p dangerouslySetInnerHTML={{ __html: this.translation.id.info }}></Type.p>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledID>
    );
  }
}
