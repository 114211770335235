import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

//IMAGES
import { ReactComponent as ScrollArrowIcon } from "../../icons/scrollArrow.svg";

const StyledScrollArrow = styled(NanoFlex)`
  width: auto;
  height: auto;
  cursor: pointer;
  .circleShape {
    border: 2px solid ${(props) => props.theme.color.main.white};
    border-radius: 60px;
    padding: 8px;
    overflow: hidden;
    svg {
      height: 16px;
      width: auto;

      path {
        fill: ${(props) => props.theme.color.main.white};
      }
    }
    &:hover {
      svg {
        -webkit-animation: scroll-slide .7s ease-in infinite;
        animation: scroll-slide .7s ease-in infinite;
      }
    }
  }

  @keyframes scroll-slide {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    25% {
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
      opacity: 0;
    }
    50% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

export default class ScrollArrow extends Component {
  render() {
    return (
      <StyledScrollArrow>
        <NanoFlex className="circleShape">
          <ScrollArrowIcon />
        </NanoFlex>
      </StyledScrollArrow>
    );
  }
}
