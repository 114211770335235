import React from "react";
import BaseComponent from "./../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex.js";

//Components
import { ContactForm } from "./ContactForm.js";
import { ContactInfo } from "./ContactInfo.js";

const StyledContacts = styled(NanoFlex)`
  height: auto;
`;

export class Contacts extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledContacts id="contacts" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        <ContactForm />
        <ContactInfo />
      </StyledContacts>
    );
  }
}
