import React from "react";
import BaseComponent from "./../../BaseComponent.js";
import styled from "styled-components";
import ScrollspyNav from "react-scrollspy-nav";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import CTAButton from "../../Global/Elements/Buttons/CTAbutton.js";
import ScrollArrow from "../../Global/Elements/Buttons/ScrollArrow.js";

//Images
import HeaderImage from "../../Global/images/headerImg.png";

const StyledHeader = styled(NanoFlex)`
  height: auto;
  position: relative;
  .headerMain {
    position: relative;
    height: 90vh;
    clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0 70%, 0% 0%);
    background-image: url(${HeaderImage});
    background-repeat: no-repeat, repeat;
    background-size: cover;

    .headerTitle {
      height: auto;
      width: 456px;
      text-align: center;
      margin-bottom: 48px;
      h1 {
        color: ${(props) => props.theme.color.main.white};
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    .subTitle {
      height: auto;
      width: 426px;
      text-align: center;
      margin-bottom: 48px;
      h3 {
        color: ${(props) => props.theme.color.main.white};
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    .ctaWrapper {
      height: auto;
      a {
        text-decoration: none;
      }
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      clip-path: polygon(100% 0, 100% 70%, 50% 100%, 0 70%, 0% 0%);
      /* background-color: #00315b; */
      mix-blend-mode: hard-light;
      background: linear-gradient(-45deg, #1d2a30, #374754, #00315b);
      background-size: 400% 400%;
      animation: gradient 10s ease infinite;
    }
  }
  .scrollWrapper {
    width: auto;
    height: auto;
    position: absolute;
    right: 32px;
    bottom: 33%;
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 660px) {
    .headerMain {
      height: 70vh;
      clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0% 0%);
      padding: 0 16px;
      .headerTitle {
        width: 100%;
      }
      .subTitle {
        width: 100%;
      }
      &:before {
        content: "";
        position: absolute;
        clip-path: polygon(100% 0, 100% 80%, 50% 100%, 0 80%, 0% 0%);
      }
    }
    .scrollWrapper {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    .headerMain {
      clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0% 0%);
      .headerTitle {
        margin-bottom: 32px;
        h1 {
          font-size: 48px;
          line-height: 52px;
        }
      }
      .subTitle {
        margin-bottom: 32px;
      }
      &:before {
        content: "";
        position: absolute;
        clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0% 0%);
      }
    }
  }

  @media only screen and (max-width: 340px) {
    .headerMain {
      .headerTitle {
        margin-bottom: 16px;
        h1 {
          font-size: 38px;
          line-height: 42px;
        }
      }
      .subTitle {
        margin-bottom: 16px;
        h3 {
          font-size: 20px;
          line-height: 22px;
        }
      }
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export class Header extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledHeader id="home" justifyContent="flex-start" data-aos="zoom-out" data-aos-easing="linear" data-aos-duration="3000">
        <NanoFlex className="headerMain" flexDirection="column">
          <NanoFlex className="headerTitle" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <Type.h1>
              <b>{this.translation.header.title}</b>
            </Type.h1>
          </NanoFlex>

          <NanoFlex className="subTitle" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="2250">
            <Type.h3>{this.translation.header.subtitle}</Type.h3>
          </NanoFlex>
          <NanoFlex className="ctaWrapper" data-aos="fade-up" data-aos-duration="2500">
            <ScrollspyNav scrollTargetIds={["contacts"]} scrollDuration="500" headerBackground="true">
              <a href="#contacts">
                <CTAButton label={this.translation.global.cta} />
              </a>
            </ScrollspyNav>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="scrollWrapper">
          <ScrollspyNav scrollTargetIds={["about"]} scrollDuration="500" headerBackground="true">
            <a href="#about">
              <ScrollArrow />
            </a>
          </ScrollspyNav>
        </NanoFlex>
      </StyledHeader>
    );
  }
}
