import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import ScrollspyNav from "react-scrollspy-nav";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//COMPONENTS
import MenuBurguer from "../../Animations/MenuBurguer.js";

//IMAGES
import MedialogLogo from "./../../Global/images/MEDIALOG-logo.svg";
import { ReactComponent as Facebook } from "./../../Global/icons/facebook.svg";
import { ReactComponent as Instagram } from "./../../Global/icons/instagram.svg";
import { ReactComponent as Linkedin } from "./../../Global/icons/linkedin.svg";

const StyledNavbar = styled(NanoFlex)`
  height: auto;
  width: auto;
  .nav {
    height: auto;
    width: auto;
    position: fixed;
    top: 0;
    z-index: 9;
    background-color: rgba(29, 42, 48, 0.5);
    padding: 16px 32px;
    border-radius: 0 0 32px 32px;
    user-select: none;
    transition: ${(props) => props.theme.transition};
    .navBlock {
      width: auto;
      margin-right: 5vw;

      .logoWrapper {
        width: auto;
        height: auto;
        transition: ${(props) => props.theme.transition};
        cursor: pointer;
        img {
          height: 100%;
          max-height: 30px;
          width: 100%;
          object-fit: contain;
          transition: ${(props) => props.theme.transition};
        }
      }
      .navItemsList {
        height: auto;
        .navItem {
          margin-right: 24px;
          width: max-content;
          a {
            text-decoration: none;
            h5 {
              color: ${(props) => props.theme.color.main.white};
            }
            &:after {
              content: "";
              height: 2px;
              width: 0%;
              margin-top: 2px;
              background-color: ${(props) => props.theme.color.main.yellow};
              position: relative;
              display: flex;
              transition: ${(props) => props.theme.transition};
            }
            &:hover {
              &:after {
                content: "";
                height: 2px;
                width: 100%;
                margin-top: 2px;
                background-color: ${(props) => props.theme.color.main.yellow};
                position: relative;
                display: flex;
                transition: ${(props) => props.theme.transition};
              }
            }
          }
          .is-active {
            h5 {
              font-weight: bold;
              color: ${(props) => props.theme.color.main.yellow};
            }
            &:after {
              content: "";
              height: 2px;
              width: 100%;
              margin-top: 2px;
              background-color: ${(props) => props.theme.color.main.yellow};
              position: relative;
              display: flex;
              transition: ${(props) => props.theme.transition};
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }

      .langWrapper {
        width: auto;
        margin-right: 16px;
        .langItem {
          width: auto;
          cursor: pointer;
          margin-left: 4px;
          transition: ${(props) => props.theme.transition};
          h6 {
            color: ${(props) => props.theme.color.main.white};
            line-height: 10px;
          }
          &:before {
            content: "|";
            display: flex;
            color: ${(props) => props.theme.color.main.white};
            font-size: 10px;
            margin-right: 4px;
          }
          &:first-child {
            &:before {
              display: none;
            }
          }
          &:hover {
            opacity: 0.7;
            transition: ${(props) => props.theme.transition};
          }
        }
        .activeLangItem {
          pointer-events: none;
          h6 {
            font-weight: bold;
            color: ${(props) => props.theme.color.main.yellow};
          }
        }
      }
      .socialWrapper {
        width: auto;
        .socialIcon {
          margin-left: 12px;
          width: auto;
          transition: ${(props) => props.theme.transition};
          a {
            text-decoration: none;
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            svg {
              height: 16px;
              width: auto;
              path {
                fill: ${(props) => props.theme.color.main.white};
              }
            }
          }
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .navMobile {
        display: none;
        transform: scale(0.8);
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  /* FIXED NAVBAR */
  .fixedNav {
    transition: ${(props) => props.theme.transition};
    padding: 6px 32px;
    background-color: ${(props) => props.theme.color.main.darkBlue};
    box-shadow: ${(props) => props.theme.boxShadow};

    .navBlock {
      .logoWrapper {
        img {
          max-height: 18px;
          transition: ${(props) => props.theme.transition};
        }
      }
    }
  }

  @media only screen and (max-width: 1074px) {
    .nav {
      width: 90vw;
      padding: 12px 32px;
      .navBlock {
        .logoWrapper {
          img {
            transition: ${(props) => props.theme.transition};
            max-height: 18px;
          }
        }
        .navItemsList {
          display: none;
        }
        .langWrapper {
          display: none;
        }
        .socialWrapper {
          display: none;
        }
        .navMobile {
          display: flex;
        }
      }
    }

    /* FIXED NAVBAR */
    .fixedNav {
      padding: 6px 32px;
      .navBlock {
        .logoWrapper {
          img {
            transition: ${(props) => props.theme.transition};
            max-height: 14px;
            width: auto;
          }
        }
      }
    }
  }
`;

export class Navbar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      fixedNav: null,
    };
  }
  // FIXED NAVBAR
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("scroll", () => {
      let fixedNav = "normal";
      if (window.scrollY === 0) {
        fixedNav = null;
      } else {
        fixedNav = "top";
      }
      this.setState({ fixedNav });
      this.state = {
        expand: false,
      };
    });
  }
  // END FIXED NAVBAR

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  scrollToTop = (v) => {
    // window.scrollTo(0, 0);
    this.setState({ selected: v });
  };

  //LANGUAGUE
  onHeaderLanguageChange = () => {
    this.getAllComponentsData();
  };

  changeLanguage = (langId) => {
    window.dispatchEvent(new CustomEvent("languageChange", { detail: langId }));
  };

  //MENU BURGER
  onMenuBurgerOpenChange = (val) => {
    if (this.props.onMenuBurgerOpenChange) this.props.onMenuBurgerOpenChange(val);
  };
  //END MENU BURGER
  render() {
    let langId = this.getLanguage();
    return (
      <StyledNavbar>
        <NanoFlex className={`nav ${this.state.fixedNav ? "fixedNav" : ""}`} justifyContent="space-between">
          <NanoFlex className="navBlock">
            <NanoFlex className="logoWrapper" onClick={() => window.scrollTo(0, 0)}>
              <img src={MedialogLogo} alt="MEDIALOG Logo" />
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="navBlock">
            <ScrollspyNav scrollTargetIds={["about", "id", "businessArea", "products", "clients", "contacts"]} activeNavClass="is-active" scrollDuration="500" headerBackground="true">
              <NanoFlex className="navItemsList">
                <NanoFlex className="navItem">
                  <a href="#about">
                    <Type.h5>{this.translation.navbar.about}</Type.h5>
                  </a>
                </NanoFlex>
                <NanoFlex className="navItem">
                  <a href="#id">
                    <Type.h5>{this.translation.navbar.id}</Type.h5>
                  </a>
                </NanoFlex>
                {/* <NanoFlex className="navItem">
                  <a href="#skills">
                    <Type.h5>{this.translation.navbar.skills}</Type.h5>
                  </a>
                </NanoFlex> */}
                <NanoFlex className="navItem">
                  <a href="#businessArea">
                    <Type.h5>{this.translation.navbar.businessArea}</Type.h5>
                  </a>
                </NanoFlex>
                <NanoFlex className="navItem">
                  <a href="#products">
                    <Type.h5>{this.translation.navbar.products}</Type.h5>
                  </a>
                </NanoFlex>
                <NanoFlex className="navItem">
                  <a href="#clients">
                    <Type.h5>{this.translation.navbar.clients}</Type.h5>
                  </a>
                </NanoFlex>
                <NanoFlex className="navItem">
                  <a href="#contacts">
                    <Type.h5>{this.translation.navbar.contacts}</Type.h5>
                  </a>
                </NanoFlex>
              </NanoFlex>
            </ScrollspyNav>
          </NanoFlex>

          <NanoFlex className="navBlock" justifyContent="flex-end">
            {/* DESCOMENTAR QUANDO EXISTIREM TRADUÇÕES */}
            {/* <NanoFlex className="langWrapper">
              <NanoFlex className={`langItem ${langId === null ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(null)}>
                <Type.h6>PT</Type.h6>
              </NanoFlex>
              <NanoFlex className={`langItem ${langId === 1 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(1)}>
                <Type.h6>ES</Type.h6>
              </NanoFlex>
              <NanoFlex className={`langItem ${langId === 2 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(2)}>
                <Type.h6>ENG</Type.h6>
              </NanoFlex>
            </NanoFlex> */}
            <NanoFlex className="socialWrapper">
              <NanoFlex className="socialIcon">
                <a href="https://www.facebook.com/Medialogeuropa" rel="noopener noreferrer" target="_blank">
                  <Facebook />
                </a>
              </NanoFlex>
              <NanoFlex className="socialIcon">
                <a href="https://www.instagram.com/medialogeuropa/" rel="noopener noreferrer" target="_blank">
                  <Instagram />
                </a>
              </NanoFlex>
              <NanoFlex className="socialIcon">
                <a href="https://www.linkedin.com/company/medialog-europa/" rel="noopener noreferrer" target="_blank">
                  <Linkedin />
                </a>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="navMobile" justifyContent="flex-end">
              <MenuBurguer open={this.props.menuBurgerOpen} onOpenChange={this.onMenuBurgerOpenChange} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledNavbar>
    );
  }
}
