import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

const StyledCTAButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  user-select: none;
  .ctaButtonWrapper {
    width: 200px;
    height: auto;
    padding: 8px;
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    text-transform: uppercase;
    border: none;
    border: 0;
    background-color: ${(props) => props.theme.color.main.yellow};
    transition: ${(props) => props.theme.transition};
    text-transform: uppercase;
    color: ${(props) => props.theme.color.main.font};
    font-size: 18px;
    font-weight: bold;
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    opacity: ${(props) => (props.disabled ? "0.4" : "1")};
    border-radius: 20px;
    &:hover {
      border-radius: 10px;
      transition: ${(props) => props.theme.transition};
      letter-spacing: 2px;
    }
  }
`;

const CTAButton = (props) => {
  return (
    <StyledCTAButton className="ctaBtn" disabled={props.disabled}>
      <button className="ctaButtonWrapper" type={props.type} onClick={() => props.clickAction(props.id)}>
        {props.label}
      </button>
    </StyledCTAButton>
  );
};

// Set default props
CTAButton.defaultProps = {
  id: 0,
  label: "",
  type: "submit",
  clickAction: () => null,
};

export default CTAButton;
