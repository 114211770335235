import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import CTAButton from "../../Global/Elements/Buttons/CTAbutton.js";
import Input from "../../Global/Elements/Forms/Input.js";
import Textarea from "../../Global/Elements/Forms/Textarea.js";

//IMAGES
import MedialogLogo from "../../Global/images/MEDIALOG-logo.svg";

const StyledGuidelines = styled(NanoFlex)`
  padding: 30px;
  height: auto;
  background-color: ${(props) => props.theme.color.main.white};
  .guidelinesWrapper {
    padding: 30px;
    background-color: ${(props) => props.theme.color.main.font};
    .headerGuidelines {
      .logoWrapper {
        height: auto;
        width: 200px;
        margin-bottom: 16px;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      h1 {
        color: ${(props) => props.theme.color.main.white};
      }
    }

    .guidelineBlock {
      margin-top: 16px;
      padding: 8px;
      border-top: 1px solid ${(props) => props.theme.color.main.white};

      .titleBlock {
        margin: 8px 0;
        h4 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
      .blockWrapper {
        padding: 16px;
        background-color: ${(props) => props.theme.color.main.white};
        .colorBlock {
          margin-right: 8px;
          margin-bottom: 8px;
          width: auto;
          height: auto;
          .colorTitle {
            margin-bottom: 4px;
            h5 {
              color: ${(props) => props.theme.color.main.font};
            }
          }
          .color {
            background-color: ${(props) => props.theme.color.main.font};
            width: 184px;
            height: 60px;
            position: relative;
            .hexCode {
              position: absolute;
              right: 8px;
              bottom: 8px;
              height: auto;
              width: auto;
              h6 {
                color: ${(props) => props.theme.color.main.font};
              }
            }
          }
          /* MAIN COLORS */
          .font {
            background-color: ${(props) => props.theme.color.main.font};
            border: ${(props) => props.theme.color.main.font};
            .hexCode {
              h6 {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
          .secondFont {
            background-color: ${(props) => props.theme.color.main.secondFont};
            border: ${(props) => props.theme.color.main.secondFont};
            .hexCode {
              h6 {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
          .blue {
            background-color: ${(props) => props.theme.color.main.blue};
            border: ${(props) => props.theme.color.main.blue};
            .hexCode {
              h6 {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
          .yellow {
            background-color: ${(props) => props.theme.color.main.yellow};
            border: ${(props) => props.theme.color.main.yellow};
          }
          .white {
            background-color: ${(props) => props.theme.color.main.white};
            border: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
          }
          /* COLOR PALETTE */

          .lighterGrey {
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
            border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
          }
        }
        .typeBlock {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .formWrapper {
          margin-bottom: 8px;
          .titleForm {
            margin-bottom: 4px;
            text-transform: uppercase;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export class Guidelines extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openContactModal: false,
      openFeedbackModal: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledGuidelines flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="guidelinesWrapper" flexDirection="column">
          {/* HEADER GUIDLINES */}
          <NanoFlex className="headerGuidelines" flexDirection="column">
            <NanoFlex className="logoWrapper">
              <img src={MedialogLogo} alt="MEDIALOG Logo" />
            </NanoFlex>
            <Type.h1>
              <b>GUIDELINES</b>
            </Type.h1>
          </NanoFlex>

          {/* COLOR PALETTE */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>COLOR PALETTE</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexWrap="wrap" justifyContent="flex-start">
              {/* COLOR BLOCK - font */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.font</Type.h5>
                </NanoFlex>
                <NanoFlex className="color font">
                  <NanoFlex className="hexCode">
                    <Type.h6>#1D2A30</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>

              {/* COLOR BLOCK - secondFont */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.secondFont</Type.h5>
                </NanoFlex>
                <NanoFlex className="color secondFont">
                  <NanoFlex className="hexCode">
                    <Type.h6>#374754</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>

              {/* COLOR BLOCK - blue */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.blue</Type.h5>
                </NanoFlex>
                <NanoFlex className="color blue">
                  <NanoFlex className="hexCode">
                    <Type.h6>#045091</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>

              {/* COLOR BLOCK - yellow */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.yellow</Type.h5>
                </NanoFlex>
                <NanoFlex className="color yellow">
                  <NanoFlex className="hexCode">
                    <Type.h6>#FFBA07</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>

              {/* COLOR BLOCK - white */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>main.white</Type.h5>
                </NanoFlex>
                <NanoFlex className="color white">
                  <NanoFlex className="hexCode">
                    <Type.h6>#FFFFFF</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexWrap="wrap" justifyContent="flex-start">
              {/* COLOR BLOCK - lighterGrey */}
              <NanoFlex className="colorBlock" flexDirection="column" alignItems="flex-start">
                <NanoFlex className="colorTitle" justifyContent="flex-start">
                  <Type.h5>colorsPalette.lighterGrey</Type.h5>
                </NanoFlex>
                <NanoFlex className="color lighterGrey">
                  <NanoFlex className="hexCode">
                    <Type.h6>#F2F2F2</Type.h6>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* TYPOGRAPHY */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>TYPOGRAPHY</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h1>
                  <b>TYPE.H1</b> | 24px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h1>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h2>
                  <b>TYPE.H2</b> | 22px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h2>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h3>
                  <b>TYPE.H3</b> | 20px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h3>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h4>
                  <b>TYPE.H4</b> | 18px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h5>
                  <b>TYPE.H5</b> | 16px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h5>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.h6>
                  <b>TYPE.H6</b> | 10px | Lorem ipsum dolor sit amet, <i>consectetur adipiscing elit</i>...
                </Type.h6>
              </NanoFlex>
              <NanoFlex className="typeBlock" justifyContent="flex-start">
                <Type.p>
                  <b>TYPE.P</b> | 12px | Lorem ipsum <em>dolor sit amet</em>, <i>consectetur adipiscing elit</i>...
                </Type.p>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* BUTTONS */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>BUTTONS</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <CTAButton label="Ligue-se nós" />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>

          {/* FORMS */}
          <NanoFlex className="guidelineBlock" flexDirection="column" alignItems="flex-start">
            <NanoFlex className="titleBlock" justifyContent="flex-start">
              <Type.h4>
                <b>FORMS</b>
              </Type.h4>
            </NanoFlex>
            <NanoFlex className="blockWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* INPUT*/}
              <NanoFlex className="formWrapper">
                <Input title="Input" placeholder="Placeholder" />
              </NanoFlex>
              {/* TEXTAREA */}
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Textarea title="Textarea" placeholder="Placeholder" />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledGuidelines>
    );
  }
}
