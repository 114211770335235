import React from "react";
import BaseComponent from "./../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//IMAGES
import { ReactComponent as StrategyIcon } from "../../Global/icons/strategy.svg";
import { ReactComponent as DesignIcon } from "../../Global/icons/design.svg";
import { ReactComponent as DevIcon } from "../../Global/icons/dev.svg";
import { ReactComponent as DeployIcon } from "../../Global/icons/deploy.svg";
import MicrosoftLogo from "../../Global/images/Microsoft_Partner.png";
import OracleLogo from "../../Global/images/Oracle_Partner.png";

const StyledAbout = styled(NanoFlex)`
  padding: 48px;
  height: auto;
  .sectionTilte {
    margin-bottom: 32px;
    text-align: center;
    .title {
      margin-bottom: 16px;
    }
    .subtitle {
      max-width: 460px;
      text-align: center;
    }
  }
  .processList {
    margin-top: 48px;
    .processItem {
      width: auto;
      height: auto;
      margin-right: 32px;
      .iconWrapper {
        height: auto;
        svg {
          height: 48px;
          width: auto;
          path {
            fill: ${(props) => props.theme.color.main.blue};
          }
        }
      }
      .processTitle {
        margin: 16px 0 12px 0;
        height: auto;
      }
      .processInfo {
        text-align: center;
        max-width: 200px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .partnersWrapper {
    margin-top: 42px;
    flex-wrap: wrap;
    .partnerLogo {
      height: 28px;
      width: auto;
      min-width: 200px;
      margin: 16px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        filter: grayscale(100%);
        opacity: 0.5;
        transition: ${(props) => props.theme.transition};
      }
      /* &:last-child {
        margin-right: 0;
      } */
      &:hover {
        img {
          filter: none;
          opacity: 1;
          transition: ${(props) => props.theme.transition};
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 860px) {
    .processList {
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 16px;
      .processItem {
        width: 142px;
        margin: 16px;
        &:last-child {
          margin-right: 16px;
        }
      }
    }
  }
  @media only screen and (max-width: 448px) {
    .processList {
      .processItem {
        width: 100%;
      }
    }
  }
`;

export class About extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledAbout id="about" flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="sectionTilte" flexDirection="column" data-aos="fade-up">
          <NanoFlex className="title">
            <Type.h2>{this.translation.about.title}</Type.h2>
          </NanoFlex>
          <NanoFlex className="subtitle">
            <Type.h5>{this.translation.about.subtilte}</Type.h5>
          </NanoFlex>
        </NanoFlex>

        <NanoFlex className="processList">
          {/* STRATEGY */}
          <NanoFlex className="processItem" flexDirection="column" data-aos="fade-up" data-aos-duration="500">
            <NanoFlex className="iconWrapper">
              <StrategyIcon />
            </NanoFlex>
            <NanoFlex className="processTitle">
              <Type.p>
                <b>{this.translation.about.process.strategy.title}</b>
              </Type.p>
            </NanoFlex>
            <NanoFlex className="processInfo" alignItems="flex-start">
              <Type.p>{this.translation.about.process.strategy.info}</Type.p>
            </NanoFlex>
          </NanoFlex>

          {/* DESIGN */}
          <NanoFlex className="processItem" flexDirection="column" data-aos="fade-up" data-aos-duration="1000">
            <NanoFlex className="iconWrapper">
              <DesignIcon />
            </NanoFlex>
            <NanoFlex className="processTitle">
              <Type.p>
                <b>{this.translation.about.process.design.title}</b>
              </Type.p>
            </NanoFlex>
            <NanoFlex className="processInfo" alignItems="flex-start">
              <Type.p>{this.translation.about.process.design.info}</Type.p>
            </NanoFlex>
          </NanoFlex>

          {/* DEV */}
          <NanoFlex className="processItem" flexDirection="column" data-aos="fade-up" data-aos-duration="1500">
            <NanoFlex className="iconWrapper">
              <DevIcon />
            </NanoFlex>
            <NanoFlex className="processTitle">
              <Type.p>
                <b>{this.translation.about.process.dev.title}</b>
              </Type.p>
            </NanoFlex>
            <NanoFlex className="processInfo" alignItems="flex-start">
              <Type.p>{this.translation.about.process.dev.info}</Type.p>
            </NanoFlex>
          </NanoFlex>

          {/* DEPLOY */}
          <NanoFlex className="processItem" flexDirection="column" data-aos="fade-up" data-aos-duration="2050">
            <NanoFlex className="iconWrapper">
              <DeployIcon />
            </NanoFlex>
            <NanoFlex className="processTitle">
              <Type.p>
                <b>{this.translation.about.process.deploy.title}</b>
              </Type.p>
            </NanoFlex>
            <NanoFlex className="processInfo" alignItems="flex-start">
              <Type.p>{this.translation.about.process.deploy.info}</Type.p>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="partnersWrapper">
          <NanoFlex className="partnerLogo" data-aos="fade-up-right">
            <img src={MicrosoftLogo} alt="Microsoft Partner Silver Application Development" />
          </NanoFlex>
          <NanoFlex className="partnerLogo" data-aos="fade-up-left">
            <img src={OracleLogo} alt="Oracle Gold Partner" />
          </NanoFlex>
        </NanoFlex>
      </StyledAbout>
    );
  }
}
