import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

//IMAGES
import { ReactComponent as Check } from "../../icons/check.svg";

const StyledCheckbox = styled(NanoFlex)`
  width: auto;
  .checkboxWrapper {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 2px solid ${(props) => props.theme.color.main.white};
    cursor: pointer;
    opacity: 1;
    svg {
      opacity: 0;
      path {
        fill: ${(props) => props.theme.color.main.white};
      }
    }
    /* &:hover {
      opacity: 1;
    } */
  }
  .checked {
    opacity: 1;
    border-color: ${(props) => props.theme.color.main.yellow};
    svg {
      opacity: 1;
      path {
        fill: ${(props) => props.theme.color.main.yellow};
      }
    }
  }
`;

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked ?? false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: this.props.checked ?? false });
    }

    if (this.state.checked !== prevState.checked && this.props.onCheckedChange) {
      this.props.onCheckedChange(this.state.checked);
    }
  }

  toggleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  render() {
    return (
      <StyledCheckbox className="checkbox">
        <NanoFlex className={`checkboxWrapper ${this.state.checked ? "checked" : ""}`} onClick={this.toggleCheck}>
          <Check />
        </NanoFlex>
      </StyledCheckbox>
    );
  }
}

// Set default props
Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
