import { BaseService } from "./BaseService";

const _apiUrl = "api/Home/";

export class HomeService extends BaseService {
  sendContact(contact) {
    return this.request(_apiUrl + "SendContact", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(contact),
    });
  }
}

const _homeService = new HomeService();

export default _homeService;