import React from "react";
import styled from "styled-components";

import NanoFlex from "../Global/NanoFlex.js";

const StyledLoading = styled(NanoFlex)`
  .loadingWrapper {
    width: auto;
    height: auto;
    position: relative;
    span {
      width: 10px;
      height: 10px;
      background: ${(props) => props.theme.color.main.yellow};
      border-radius: 50%;
      -webkit-animation: loading 1s infinite ease-in-out;
      animation: loading 1s infinite ease-in-out;
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
    span:nth-child(1) {
      left: 0px;
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
    span:nth-child(2) {
      left: 15px;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    span:nth-child(3) {
      left: 30px;
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }
  }

  @keyframes loading {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      background: ${(props) => props.theme.color.main.yellow};
      opacity: 1;
    }
    25% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0.4;
    }
    50% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0.8;

    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      background:${(props) => props.theme.color.main.yellow};
      opacity: 1;
    }
  }
`;

const Loading = (props) => {
  return (
    <StyledLoading className="loading">
      <NanoFlex className="loadingWrapper">
        <span></span>
        <span></span>
        <span></span>
      </NanoFlex>
    </StyledLoading>
  );
};

export default Loading;

// --- // Documentation // --- //
/*

import Loading from './../Nano/Loading.js';

<Loading />
*/
