import React from "react";
import BaseComponent from "./../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//IMAGES
import { ReactComponent as ReactLogo } from "../../Global/icons/reactLogo.svg";
import { ReactComponent as JavascriptLogo } from "../../Global/icons/javascriptLogo.svg";
import { ReactComponent as TypescriptLogo } from "../../Global/icons/typescriptLogo.svg";
import { ReactComponent as NetCoreLogo } from "../../Global/icons/netCoreLogo.svg";
import { ReactComponent as SQLLogo } from "../../Global/icons/sqlLogo.svg";
import { ReactComponent as OracleLogo } from "../../Global/icons/oracleLogo.svg";

const StyledSkills = styled(NanoFlex)`
  padding: 48px;
  height: auto;
  background-color: ${(props) => props.theme.color.main.white};
  .titleSection {
    margin-bottom: 32px;
  }
  .logosList {
    .logoWrapper {
      width: auto;
      margin-right: 40px;
      svg {
        max-height: 80px;
        max-width: 88px;
        opacity: 0.5;
        transition: ${(props) => props.theme.transition};

        path {
          filter: grayscale(1);
          transition: ${(props) => props.theme.transition};
        }
      }
      &:hover {
        svg {
          transform: scale(1.3);
          opacity: 1;
          transition: ${(props) => props.theme.transition};
          path {
            filter: unset;
            transition: ${(props) => props.theme.transition};
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 880px) {
    .logosList {
      .logoWrapper {
        svg {
          max-height: 60px;
          max-width: 68px;
        }
      }
    }
  }
  @media only screen and (max-width: 690px) {
    padding: 48px 16px 32px 16px;
    .titleSection {
      justify-content: center;
      text-align: center;
      margin-bottom: 16px;
    }
    .logosList {
      flex-wrap: wrap;
      .logoWrapper {
        height: auto;
        margin: 16px;
        &:last-child {
          margin-right: 16px;
        }
      }
    }
  }
`;

export class Skills extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledSkills id="skills" flexDirection="column"  data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
        {/* <NanoFlex className="titleSection" data-aos="fade-down">
          <Type.h3>
            <b>{this.translation.skills.ourSkills}</b>
          </Type.h3>
        </NanoFlex> */}
        <NanoFlex className="logosList">
          <NanoFlex className="logoWrapper" data-aos="flip-left" data-aos-duration="500">
            <ReactLogo />
          </NanoFlex>
          <NanoFlex className="logoWrapper" data-aos="flip-left" data-aos-duration="1000">
            <JavascriptLogo />
          </NanoFlex>
          <NanoFlex className="logoWrapper" data-aos="flip-left" data-aos-duration="1500">
            <TypescriptLogo />
          </NanoFlex>
          <NanoFlex className="logoWrapper" data-aos="flip-left" data-aos-duration="2050">
            <NetCoreLogo />
          </NanoFlex>
          <NanoFlex className="logoWrapper" data-aos="flip-left" data-aos-duration="2500">
            <SQLLogo />
          </NanoFlex>
          <NanoFlex className="logoWrapper" data-aos="flip-left" data-aos-duration="3000">
            <OracleLogo />
          </NanoFlex>
        </NanoFlex>
      </StyledSkills>
    );
  }
}
