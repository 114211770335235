import React from "react";
import BaseComponent from "./../../BaseComponent.js";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Components

//IMAGES
import Asento from "../../Global/images/clients/asento.jpg";
import Avantmidia from "../../Global/images/clients/avantmidia.png";
import BarOgilvy from "../../Global/images/clients/BarOgilvy.jpg";
import Blueenergy from "../../Global/images/clients/blueenergy.png";
import Btaes from "../../Global/images/clients/Btaes.png";
import Caetsu from "../../Global/images/clients/caetsu.jpg";
import CaetsuTwo from "../../Global/images/clients/caetsuTwo.png";
import Carat from "../../Global/images/clients/Carat.png";
import CreativeSales from "../../Global/images/clients/creative-sales.jpg";
import Elevus from "../../Global/images/clients/elevus.png";
import Fcblisboa from "../../Global/images/clients/fcblisboa.png";
import Gesmedia from "../../Global/images/clients/gesmedia.png";
import Glance from "../../Global/images/clients/glance.svg";
import Grey from "../../Global/images/clients/Grey.png";
import Imaxe from "../../Global/images/clients/Imaxe.jpg";
import Initiative from "../../Global/images/clients/Initiative.png";
import Mccann from "../../Global/images/clients/Mccann.png";
import Mediagate from "../../Global/images/clients/mediagate.png";
import Megameios from "../../Global/images/clients/megameios.jpg";
import NovaExpressao from "../../Global/images/clients/NovaExpressao.png";
import Opal from "../../Global/images/clients/opal.png";
import Partners from "../../Global/images/clients/Partners.png";
import PublicisOne from "../../Global/images/clients/PublicisOne.png";
import RMedia from "../../Global/images/clients/rmedia.jpg";
import ShackletonChile from "../../Global/images/clients/shackleton_Chile.png";
import Shackleton from "../../Global/images/clients/shackleton.jpg";
import Starcom from "../../Global/images/clients/starcom.png";
import WundermanThompson from "../../Global/images/clients/WundermanThompson.png";
import Zenith from "../../Global/images/clients/Zenith.png";

const StyledClients = styled(NanoFlex)`
  padding-bottom: 48px;
  height: auto;
  background-color: ${(props) => props.theme.color.main.white};


  .titleSection {
    padding: 48px 48px 32px 48px;
  }
  .carouselWrapper {
    user-select: none;
    cursor: grab;
    width: 100%;
    .carouselItem {
      opacity: 0.5;
      transition: ${(props) => props.theme.transition};
      width: auto;
      img {
        max-height: 80px;
        height: 100%;
        width: 100%;
        min-width: 100px;
        object-fit: contain;
        filter: grayscale(1);
        transition: ${(props) => props.theme.transition};
      }
      &:hover {
        opacity: 1;
        transition: ${(props) => props.theme.transition};
        img {
          filter: unset;
          transition: ${(props) => props.theme.transition};
        }
      }
    }
  }
  /* CAROUSEL */
  .carousel-root {
    width: 100%;
    /* 
    height: 100%; */
    .carousel-slider {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .control-arrow {
        display: none;
      }
      .slider-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: center;
        .slider {
          display: flex;
          justify-content: flex-start;
          width: 120px;
          .slide {
            background-color: unset;
            margin-right: 48px;
          }
        }
      }
      .control-dots {
        display: none;
      }
      .carousel-status {
        display: none;
      }
    }
    .carousel {
      .axis-vertical {
        display: none;
      }
    }
  }
  /* END CAROUSEL */
  /* MEDIA QUERIES */
  @media only screen and (max-width: 480px) {
    .titleSection {
      justify-content: center;
    }
  }
`;

export class Clients extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledClients id="clients" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
        <NanoFlex className="titleSection" justifyContent="flex-start" data-aos="fade-down">
          <Type.h3>
            <b>{this.translation.clients.title}</b>
          </Type.h3>
        </NanoFlex>
        <NanoFlex className="carouselWrapper" justifyContent="flex-start" alignItems="flex-start">
          <Carousel autoPlay infiniteLoop emulateTouch useKeyboardArrows stopOnHover interval="2000" swipeScrollTolerance="50">
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem"  data-aos="flip-left" data-aos-duration="100">
              <img src={Asento} alt="Asento" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem"  data-aos="flip-left" data-aos-duration="110">
              <img src={Avantmidia} alt="Avantmidia" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="120">
              <img src={BarOgilvy} alt="Bar Ogilvy" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="130">
              <img src={Blueenergy} alt="Bluenergy" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="140">
              <img src={Btaes} alt="Btaes" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="150">
              <img src={Caetsu} alt="Caetsu" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="160">
              <img src={CaetsuTwo} alt="Caetsu Two" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="170">
              <img src={Carat} alt="Carat" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="180">
              <img src={CreativeSales} alt="Creative Sales" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="190">
              <img src={Elevus} alt="Elevus" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="200">
              <img src={Fcblisboa} alt="FCB Lisboa" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="210">
              <img src={Gesmedia} alt="Gesmedia" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="220">
              <img src={Glance} alt="Glance" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="230">
              <img src={Grey} alt="Grey" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="240">
              <img src={Imaxe} alt="Imaxe" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="250">
              <img src={Initiative} alt="Initiative" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="260">
              <img src={Mccann} alt="McCann" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="270">
              <img src={Mediagate} alt="Mediagate" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="280">
              <img src={Megameios} alt="Megameios" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="290">
              <img src={NovaExpressao} alt="Nova Expressao" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="300">
              <img src={Opal} alt="Opal" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="310">
              <img src={Partners} alt="Partners" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="320">
              <img src={PublicisOne} alt="PublicisOne" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="330">
              <img src={RMedia} alt="RMedia" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="340">
              <img src={Shackleton} alt="Shackleton" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem" data-aos="flip-left" data-aos-duration="350">
              <img src={ShackletonChile} alt="Shackleton Chile" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem">
              <img src={Starcom} alt="Starcom" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem">
              <img src={WundermanThompson} alt="Wunderman Thompson" />
            </NanoFlex>
            {/* LOGO ITEM */}
            <NanoFlex className="carouselItem">
              <img src={Zenith} alt="Zenith" />
            </NanoFlex>
          </Carousel>
        </NanoFlex>
      </StyledClients>
    );
  }
}
