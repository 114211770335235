import React from "react";
import { NavLink, Link } from "react-router-dom";

import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import { ReactComponent as Facebook } from "./../../Global/icons/facebook.svg";
import { ReactComponent as Instagram } from "./../../Global/icons/instagram.svg";
import { ReactComponent as Linkedin } from "./../../Global/icons/linkedin.svg";

const StyledMenuMobile = styled(NanoFlex)`
  display: ${(props) => (props.show ? "flex" : "none")};
  z-index: 7;
  background: ${(props) => props.theme.color.main.secondFont};
  position: fixed;
  top: 0;
  left: 0;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  .navItemsWrapper {
    display: ${(props) => (props.formationDetail ? "none" : "flex")};
    height: auto;
    .navItemsList {
      /* padding: 0 32px 0 48px; */
      .navItem {
        margin-bottom: 16px;
        -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

        a {
          text-decoration: none;
          h5 {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.white};
            font-size: 28px;
            line-height: 32px;
          }

          &:hover {
            h5 {
              transition: ${(props) => props.theme.transition};
              color: ${(props) => props.theme.color.main.yellow};
            }
          }
        }
        .activeNavItem {
          pointer-events: none;
          h5 {
            color: ${(props) => props.theme.color.main.blue};
            font-weight: bold;
          }
          p {
            color: ${(props) => props.theme.color.main.blue};
            font-weight: bold;
          }
        }
        &:nth-child(1n) {
          animation-delay: 0.5s;
        }
        &:nth-child(2n) {
          animation-delay: 0.7s;
        }
        &:nth-child(3n) {
          animation-delay: 0.8s;
        }
        &:nth-child(4n) {
          animation-delay: 1s;
        }
        &:nth-child(5n) {
          animation-delay: 1.2s;
        }
        &:nth-child(6n) {
          animation-delay: 1.4s;
        }
        &:nth-child(7n) {
          animation-delay: 1.6s;
        }
        /* &:nth-child(8n) {
          animation-delay:1.8s;
        } */
      }

      .iconNavItem {
        margin-right: 16px;
        width: auto;
        a {
          svg {
            path {
              fill: ${(props) => props.theme.color.main.white};
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .navIconsWrapper {
        margin-top: 16px;
        -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation-delay: 1.8s;
      }
    }
    .languagesList {
      width: 100%;
      margin-top: 8px;
      -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation-delay: 2s;
      .langItem {
        width: auto;
        margin-right: 8px;
        cursor: pointer;
        h6 {
          color: ${(props) => props.theme.color.main.white};
        }
        &:hover {
          h6 {
            font-weight: bold;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
      .activeLangItem {
        pointer-events: none;
        h6 {
          font-weight: bold;
          color: ${(props) => props.theme.color.main.yellow};
        }
      }
    }
  }

  @keyframes slide-bottom {
    0% {
      transform: translateY(-200px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

class MenuMobile extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected ? this.props.selected : 1,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selected !== this.state.selected) this.props.onMenuBurgerOpenChange(false);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //LANGUAGUE
  onHeaderLanguageChange = () => {
    this.getAllComponentsData();
  };

  changeLanguage = (langId) => {
    window.dispatchEvent(new CustomEvent("languageChange", { detail: langId }));
  };
  //END LANGUAGUE
  onSelectClick = (v) => {
    this.setState({ selected: v });
  };

  render() {
    let langId = this.getLanguage();
    return (
      <StyledMenuMobile className="menuMobile" show={this.props.menuBurgerOpen}>
        <NanoFlex className="navItemsWrapper" flexDirection="column">
          <NanoFlex className="navItemsList" flexDirection="column">
            <NanoFlex className="navItem">
              <Link to="#about">
                <Type.h5>{this.translation.navbar.about}</Type.h5>
              </Link>
            </NanoFlex>
            <NanoFlex className="navItem">
              <Link to="#id">
                <Type.h5>{this.translation.navbar.id}</Type.h5>
              </Link>
            </NanoFlex>
            {/* <NanoFlex className="navItem">
              <Link to="#skills">
                <Type.h5>{this.translation.navbar.skills}</Type.h5>
              </Link>
            </NanoFlex> */}
            <NanoFlex className="navItem">
              <Link to="#businessArea">
                <Type.h5>{this.translation.navbar.businessArea}</Type.h5>
              </Link>
            </NanoFlex>
            <NanoFlex className="navItem">
              <Link to="#products">
                <Type.h5>{this.translation.navbar.products}</Type.h5>
              </Link>
            </NanoFlex>
            <NanoFlex className="navItem">
              <Link to="#clients">
                <Type.h5>{this.translation.navbar.clients}</Type.h5>
              </Link>
            </NanoFlex>
            <NanoFlex className="navItem">
              <Link to="#contacts">
                <Type.h5>{this.translation.navbar.contacts}</Type.h5>
              </Link>
            </NanoFlex>

            <NanoFlex className="navIconsWrapper">
              <NanoFlex className="navItem iconNavItem">
                <a href="https://www.facebook.com/Medialogeuropa" rel="noopener noreferrer" target="_blank">
                  <Facebook />
                </a>
              </NanoFlex>
              <NanoFlex className="navItem iconNavItem">
                <a href="https://www.instagram.com/medialogeuropa/" rel="noopener noreferrer" target="_blank">
                  <Instagram />
                </a>
              </NanoFlex>
              <NanoFlex className="navItem iconNavItem">
                <a href="https://www.linkedin.com/company/medialog-europa/" rel="noopener noreferrer" target="_blank">
                  <Linkedin />
                </a>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {/* DESCOMENTAR QUANDO EXISTIREM TRADUÇÕES */}
          {/* <NanoFlex className="languagesList">
            <NanoFlex className={`langItem ${langId === null ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(null)}>
              <Type.h6>PT</Type.h6>
            </NanoFlex>

            <NanoFlex className={`langItem ${langId === 1 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(1)}>
              <Type.h6>ES</Type.h6>
            </NanoFlex>

            <NanoFlex className={`langItem ${langId === 2 ? "activeLangItem" : ""}`} onClick={() => this.changeLanguage(2)}>
              <Type.h6>ENG</Type.h6>
            </NanoFlex>
          </NanoFlex> */}
        </NanoFlex>
      </StyledMenuMobile>
    );
  }
}

//Set default props
MenuMobile.defaultProps = {
  show: false,
};

export default MenuMobile;

// --- // Documentation // --- //
/*
import MenuMobile from './NavBar/MenuMobile.js';

<MenuMobile />
*/
